@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

.form-group {
  margin-bottom: 14px !important;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  src: local('Poppins'), local('Poppins'),
    url(https://www.fonts.googleapis.com/css2?family=Poppins:display=swap)
      format('woff2');
}

body {
  font-family: 'Poppins', sans-serif;
}

body::-webkit-scrollbar {
  width: 12px;
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  width: 12px;
  background-color: rgb(218, 216, 216);
}

.bg-dark {
  background-color: #0f1f26 !important;
}

.bg-primary {
  background-color: #146396 !important;
}

.text-primary {
  color: #146396 !important;
}

.text-dark {
  color: #0f1f26 !important;
}

.form-label {
  color: #0f1f26 !important;
  font-size: 14px !important;
  font-weight: 700;
}

.text-label {
  font-size: 12px;
  color: gray;
  font-weight: 400;
}

.pagination {
  border: 1px solid silver;
  max-width: fit-content;
}

.page-link {
  border: 0 !important;
}

.page-item.active .page-link {
  background-color: rgba(255, 193, 7, 0.3) !important;
  color: #000 !important;
}

.personal-detail .form-group {
  margin-bottom: 8px;
}

.form-checkbox {
  min-width: 25px;
  min-height: 25px;
}

.form-checkbox:hover {
  cursor: pointer;
}

.form-label {
  font-size: 13px;
  color: #666666;
  letter-spacing: 0.05rem;
}

.form-control {
  border-radius: 0;
}

.form-control:focus {
  border: 1px solid #146396;
  box-shadow: none;
}

.form-radio {
  min-width: 25px;
  min-height: 25px;
}

.checkbox:hover,
.radiobutton:hover {
  background: rgb(245, 245, 245);
  cursor: pointer;
}

.is-required {
  font-size: 20px !important;
}

.alert-wrapper {
  z-index: 99999 !important;
  right: 0px;
}

.list-item {
  padding: 12px 10px;
  border: 1px solid silver;
  border-top: 0;
}

.list-item:first-child {
  border-top: 1px solid silver;
}

.list-item:hover {
  cursor: pointer;
}

.list-item > span {
  font-size: 12px;
  color: rgb(114, 113, 113);
}

.list-item:hover {
  .icon-wrapper {
    display: flex;
  }
}

.icon-wrapper {
  width: 23px;
  height: 23px;
  border-radius: 100%;
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
}

.homeadvisor-signature {
  width: 350px;
  img {
    width: 60%;
  }
  p {
    font-size: 12px;
  }
}

.scrim-card {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border-top: 1px solid silver;
}

.scrim-card:hover {
  // box-shadow: rgba(0, 0, 0, 0.26) 1px 1px 7px, #fefefe 0px -1px 1px;
  box-shadow: rgba(181, 92, 0, 0.26) 1px 1px 7px, #ffffff 0px -1px 1px;
  border: 1px solid #f7c625;
  background: #fff;
}

.show-border {
  box-shadow: rgba(181, 92, 0, 0.26) 1px 1px 7px, #ffffff 0px -1px 1px;
  border: 1px solid #f7c625 !important;
}

.next-btn-container {
  position: absolute;
  bottom: 2%;
  width: 90%;
  padding: 2%;
}

.next-btn {
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .homeadvisor-signature {
    width: 90% !important;
    img {
      width: 35% !important;
    }
    p {
      font-size: 10px !important;
    }
  }
}
